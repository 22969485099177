import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getPlacements } from "api/handlers/placement/getPlacements";
import getChannels from "api/handlers/channel/getChannels";
import { prepareActions } from "store/helpers";
import { EActions, IActionTypes } from "./types";
import { AppState } from "store";
import { enqueueSnackbar } from "notistack";
import omitBy from "lodash/omitBy";
import i18n from "localization";
import getDevices from "api/handlers/device/getDevicesExtended";
import uniq from "lodash/uniq";
import { mergeByCommonKey } from "shared/helpers";
import getSensorTypes from "../../../api/handlers/getSensorTypes";
import getPlacementTypes from "../../../api/handlers/placement/getPlacementTypes";
import getRecordingPlans from "../../../api/handlers/getRecordingPlans";
import { loadISOGroup } from "store/iso_group/actions";
import { IPlacementList, IRulesConfig } from "types/placement";
import { isEmpty } from "lodash";
import patchDeviceConfig from "api/handlers/device/patchDeviceConfig";
import { IPatchPlacement } from "types/placement";

export const path = "machineDetail/placements";

const actionData = [
  [EActions.setPage, "pageIndex"],
  [EActions.setPlacements, "placements"],
  [EActions.setLoading, "loading"],
  [EActions.setReloading, "reloading"],
  [EActions.setEditing, "editing"],
  [EActions.setSort, "sort"],
  [EActions.setShowMore, "showMore"],
  [EActions.setReset],
  [EActions.setDiscard],
  [EActions.setSensorTypes, "sensorTypes"],
  [EActions.setRecordingPlans, "recordingPlans"],
  [EActions.setPlacementTypes, "placementTypes"],
  [EActions.setPlacementStatus, "placement", "is_enabled"],
  [EActions.setRuleStatus, "index", "enabled"],
  [
    EActions.setPlacementPrediction,
    "placement",
    "is_used_for_machine_running_prediction",
  ],
  [EActions.setPlacementThreshold, "placement", "running_threshold"],
  [EActions.setPlacementCable, "placement", "sensor_cable_length"],
  [EActions.setPlacementRPM, "placement", "rpm"],
  [EActions.setPlacementRPMAuto, "placement", "is_rpm_automatically_detected"],
  [EActions.setPlacementBandAlerting, "placement", "band_alerting"],
  [EActions.rename, "placement", "customName"],
  [EActions.setSensorType, "placement", "sensor_type"],
  [EActions.setPlacementType, "placement", "type"],
  [EActions.setIsoGroup, "placement", "iso_group"],
  [EActions.setRecordingPlan, "placement", "recording_plan"],
  [
    EActions.setSuppressNotificationsUntil,
    "placement",
    "suppress_notifications_until",
  ],
  [EActions.setPlacementEnvelopeSad, "placement", "envelope_sad"],
  [EActions.setInterval, "placement", "interval"],
  [EActions.setMinCount, "index", "min_count"],
  [EActions.setModelResultType, "index", "prediction_type"],
  [EActions.setSelectRule, "index", "rule"],
  [EActions.setAlertingThreshold, "index", "threshold"],
  [EActions.setIntervalToWatch, "index", "interval"],
  [EActions.setRules, "rules"],
  [EActions.addRules, "rules"],
  [EActions.removeRule, "index"],
  [EActions.updateRules, "rules"],
  [EActions.updateSuccessfullPlacement, "isSuccessfull"],
  [EActions.setIsSaving, "isSaving"],
  [EActions.setMinValue, "index", "min_value"],
  [EActions.setMaxValue, "index", "max_value"],
  [EActions.setChangeValueGradientAggRule, "index", "change_value"],
];

const actions = prepareActions<IActionTypes, EActions>(actionData, path);

export default actions;

const fetchInitialSensors = async () => {
  const sensors = await getSensorTypes();

  const emptyOption = {
    id: 0,
    name: i18n.t("devices.detail.list.cell.placement"),
  };
  sensors.unshift(emptyOption);
  return sensors;
};

const fetchInitialRecordingPlans = async () => {
  const plans = await getRecordingPlans();
  if (plans) {
    plans.results.unshift({
      id: 0,
      codename: "",
      name: "",
      settings: {},
      type: 0,
    });
    return plans.results;
  } else {
    return [];
  }
};

export const fetchPlacements =
  ({ page, pageSize, orderBy, machineId }: any) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      dispatch(loadISOGroup());
      const { machine } = getState().machineDetail.machine;
      const currentMachineId = machineId ? machineId : machine?.id;

      if (currentMachineId) {
        let placements = await getPlacements({
          machineIds: [currentMachineId],
          page,
          ps: pageSize,
          orderBy,
        });

        let channels = await getChannels({ machine: currentMachineId });
        const sensorTypes = await fetchInitialSensors();
        const placementTypes = await getPlacementTypes();
        const recordingPlans = await fetchInitialRecordingPlans();

        if (channels?.results) {
          const devices = await getDevices({
            ids: uniq(channels?.results?.map(({ device }) => device)) || [],
          });
          if (devices?.results) {
            placements = {
              ...placements,
              results: mergeByCommonKey(
                [
                  devices.results,
                  channels.results,
                  placements.results.map((placement: any) => ({
                    ...placement,
                    sensor_cable_length:
                      placement.sensor_cable_length ?? Number(0).toFixed(2),
                  })),
                ],
                ["device", "channel"],
                "id"
              ),
            };
          }
        }
        dispatch(actions[EActions.setPlacements](placements));
        dispatch(actions[EActions.setSensorTypes](sensorTypes));
        dispatch(actions[EActions.setPlacementTypes](placementTypes?.results));
        dispatch(actions[EActions.setRecordingPlans](recordingPlans));
      }
    } catch (error) {
      dispatch(actions[EActions.setLoading](false));
    }
  };

export const rename =
  (id: number, customName: string) =>
  (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions.rename(id, customName));
  };

export const update =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const { placements, initialPlacements, pageIndex, pageSize, sort } =
        getState().machineDetail.placements;
      let result: Partial<IPatchPlacement>[] = [];
      for (let i of initialPlacements.results) {
        const placement = placements.results.find(
          ({ id }: IPlacementList) => id === i.id
        );

        let updatedPlacement = { ...placement };
        const interval = placement?.alerting_config?.rules?.[0]?.interval;
        const minCount = placement?.alerting_config?.rules?.[0]?.min_count;
        if (!interval && !minCount) {
          updatedPlacement = {
            ...placement,
            alerting_config: { rules: [] },
          };
        }

        const newRule = {
          threshold: placement?.alerting_config?.rules?.[0]?.threshold || 0,
          aggregator:
            placement?.alerting_config?.rules?.[0]?.aggregator ||
            "half_over_threshold",
          class_name:
            placement?.alerting_config?.rules?.[0]?.class_name ||
            "ThresholdAggregateRule",
          min_count: placement?.alerting_config?.rules?.[0]?.min_count || 0,
          interval: placement?.alerting_config?.rules?.[0]?.interval || 0,
          prediction_type:
            placement?.alerting_config?.rules?.[0]?.prediction_type ||
            "anomaly_detector",
          enabled:
            placement?.alerting_config?.rules?.[0]?.min_count &&
            placement?.alerting_config?.rules?.[0]?.interval
              ? true
              : false,
        };

        updatedPlacement.alerting_config.rules[0] = newRule;

        let diff = omitBy(updatedPlacement, (v, k) => i[k] === v);
        if (!isEmpty(diff)) {
          if (diff.hasOwnProperty("customName")) {
            const { customName, ...rest } = diff;
            diff = { ...rest, custom_name: customName };
          }
          result = [...result, { id: i.id, ...diff }];
        }
      }

      await patchDeviceConfig({ placements: result });
      enqueueSnackbar(
        i18n.t("machine.detail.actions.placements.update.success")
      );
      dispatch(
        fetchPlacements({ page: pageIndex + 1, pageSize, orderBy: sort })
      );
    } catch (err) {
      enqueueSnackbar(i18n.t("machine.detail.actions.placements.update.fail"));
    }
  };

export const updatePlacementRules =
  (placementId: number, newRules: IRulesConfig[]) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const { placements, pageIndex, pageSize, sort } =
        getState().machineDetail.placements;
      const updatedPlacements = placements.results.map((plc: any) => {
        if (plc.id === placementId) {
          return {
            id: plc.id,
            alerting_config: {
              rules: newRules,
            },
          };
        }
        return {
          id: plc.id,
          alerting_config: plc.alerting_config,
        };
      });

      await patchDeviceConfig({ placements: updatedPlacements });
      enqueueSnackbar(
        i18n.t("machine.detail.actions.placements.update.success")
      );
      dispatch(
        fetchPlacements({ page: pageIndex + 1, pageSize, orderBy: sort })
      );
      dispatch(actions[EActions.updateSuccessfullPlacement](true));
    } catch (err) {
      dispatch(actions[EActions.updateSuccessfullPlacement](false));
      enqueueSnackbar(i18n.t("machine.detail.actions.placements.update.fail"));
    }
  };
