import { ICustomModel } from "types/customModels";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { prepareActions } from "store/helpers";
import { EActions, ICustomModelsAction } from "./types";
import { AppState } from "store";
import getCustomModelOverview from "api/handlers/getCustomModelOverview";
import getDatasetGroups from "api/handlers/eventSounds/getDatasetGroups";
export const path = "customModels";

const pairs = [
  [EActions.fetchCustomModelsOverviewFail, "error"],
  [EActions.fetchCustomModelsOverviewSuccess, "customModels"],
  [EActions.fetchCustomModelsRequest],
];

const actions = prepareActions<ICustomModelsAction, EActions>(pairs, path);

export default actions;

export const fetchCustomModelOverview =
  (id: number) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    dispatch(actions[EActions.fetchCustomModelsRequest]());
    try {
      const result = await getCustomModelOverview(id);
      const datasetIds = result.map((item: ICustomModel) => item.dataset_group);
      const datasets = await getDatasetGroups({ ids: datasetIds, page: 1 });

      const customModelResult = result.map((item: any) => {
        const datasetValue = datasets?.results.find(
          (dataset: any) => dataset.id === item.dataset_group
        );
        return {
          ...item,
          datasetName: datasetValue ? datasetValue.name : null,
        };
      });

      if (customModelResult.length) {
        dispatch(
          actions[EActions.fetchCustomModelsOverviewSuccess](customModelResult)
        );
      }
    } catch (error) {
      dispatch(actions[EActions.fetchCustomModelsOverviewFail](error));
    }
  };
