import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, IconButton, MenuItem, Popover } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { AppState } from "store";
import { useSelector } from "react-redux";
import Spinner from "components/spinner";
import { dateToString } from "shared/helpers";
import { useTranslation } from "react-i18next";
import { Player } from "./Player";
import { IPlacementList } from "types/placement";
import { IMachineList } from "types/machine";
import moment from "moment";
import appUrls from "shared/appUrls";
import { DashboardTypes } from "enums/grafana";
import getEvents from "api/handlers/event/getEvents";
import { groupEventTypes } from "shared/eventTypes";
import { IEventList } from "types/event";
import Tooltip from "components/tooltip";
import { useAsyncReducer } from "store/useCustomReducerTemplate";

export const CardDetail = ({
  id,
  getPeaks,
  classes,
  chunk,
  setPlayingAll,
  buffer,
}: any) => {
  const { machines, placements, labelFetching } = useSelector(
    (state: AppState) => state.eventSounds
  );

  const { t } = useTranslation();
  const [open, setMenuOpen] = useState<any>(null);
  const [chunkDetail, setChunkDetail] = useState(chunk);
  const [eventType, setEventType] = useState(null);
  const [note, setNote] = useState(null);

  const eventIds = useMemo(() => {
    return [chunk?.start_event?.id, chunk?.end_event?.id];
  }, [open, chunk?.end_event?.id, chunk?.start_event?.id]);

  const { data: eventData, loading } = useAsyncReducer(
    open ? "eventSelectionEventTypes" : undefined,
    getEvents,
    {
      ids: eventIds,
      pageSize: 2,
    }
  );

  useEffect(() => {
    if (!loading && eventData && eventData?.results?.length) {
      const startEvent = eventData?.results?.find(
        (item: IEventList) => item.id === chunk?.start_event?.id
      );

      const types = eventData?.results?.map((item: IEventList) => ({
        ...item,
        codename: item.type,
      }));
      const relatedEvent = groupEventTypes(types);

      setNote(startEvent.note);
      setEventType(relatedEvent[0].codename);
    }
  }, [eventData, eventIds, loading, chunk?.start_event?.id]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setMenuOpen(event.currentTarget);
      setPlayingAll();
    },
    [setPlayingAll]
  );

  const setPlaying = (value: boolean) => {
    setChunkDetail((currentChunk: any) => {
      return {
        ...currentChunk,
        isPlaying: value,
      };
    });
  };

  const handleClose = useCallback((event?: any) => {
    event.stopPropagation();
    setMenuOpen(null);
    setPlaying(false);
  }, []);

  useEffect(() => {
    const kebab = document.querySelector(`#card-${id}`);
    if (kebab) {
      kebab.addEventListener("hideKebab", handleClose);
    }
    return () => {
      if (kebab) {
        kebab.removeEventListener("hideKebab", handleClose);
      }
    };
  }, [handleClose, id]);

  const stopPropagation = useCallback((event: any) => {
    event.stopPropagation();
  }, []);

  const startDate = dateToString(new Date(chunkDetail.start_event.created_at));
  const endDate = dateToString(new Date(chunkDetail.end_event.created_at));

  const placement = placements.find(
    (item: IPlacementList) => Number(item.id) === Number(chunkDetail.placement)
  );
  const machine = machines.find(
    (item: IMachineList) => Number(item.id) === Number(chunkDetail.machine)
  );

  const handleRedirectGrafana = useCallback(() => {
    const start = moment(chunk.audio_chunk.start_datetime).valueOf();
    const end = moment(chunk.audio_chunk.end_datetime).valueOf();

    if (chunk?.machine && chunk?.placement && start && end) {
      const url =
        appUrls.machines.detail(chunk?.machine) +
        `?tab=${DashboardTypes.combined}&from=${start}&to=${end}&placement=${chunk?.placement}`;
      window.open(url, "_blank");
    }
  }, [
    chunk.audio_chunk.start_datetime,
    chunk.audio_chunk.end_datetime,
    chunk.machine,
    chunk?.placement,
  ]);

  return (
    <div id={`card-${id}`}>
      <IconButton onClick={handleClick} style={{ padding: 0 }}>
        <InfoIcon />
      </IconButton>
      <Popover
        id={open ? "simple-popover" : undefined}
        open={!!open}
        anchorEl={open}
        onClick={stopPropagation}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          paper: classes.paper,
        }}
      >
        {labelFetching || loading ? (
          <Spinner />
        ) : (
          <>
            <Box className={classes.contentWrapper}>
              <Player
                buffer={buffer}
                getPeaks={getPeaks}
                canvasWidth={500}
                canvasWaveHeight={40}
                canvasSpectroHeight={150}
                isPlaying={chunkDetail.isPlaying}
                setIsPlaying={setPlaying}
              />
              <div style={{ paddingTop: "1rem" }}>
                <div>
                  <strong>
                    {t("eventSounds.dataSelection.detail.machineName")}
                    {": "}
                  </strong>
                  <span>{machine?.name}</span>
                </div>
                <div>
                  <strong>
                    {t("eventSounds.dataSelection.detail.placementName")}
                    {": "}
                  </strong>
                  <span>{placement?.customName}</span>
                </div>
                {chunkDetail.failure_types.length ? (
                  <div>
                    <strong>
                      {t("eventSounds.dataSelection.detail.failureType")}
                      {": "}
                    </strong>
                    {chunkDetail.failure_types.map(
                      (fail: string, idx: number) => (
                        <>
                          <span>{t(`failureType.${fail}`)}</span>
                          {idx !== chunkDetail.failure_types.length - 1 && ", "}
                        </>
                      )
                    )}
                  </div>
                ) : null}
                <div>
                  <strong>
                    {t("eventSounds.dataSelection.detail.startAndEndEvent")}
                    {": "}
                  </strong>
                  <span>{`${startDate} - ${endDate}`}</span>
                </div>
                <div>
                  <strong>
                    {t("eventSounds.dataSelection.detail.lengthOfChunk")}
                    {": "}
                  </strong>
                  <span>{buffer.duration}s </span>
                </div>
                <div>
                  <strong>
                    {t("eventSounds.dataSelection.detail.eventType")}
                    {": "}
                  </strong>
                  <span>{t(`eventTypeNames.${eventType}`)}</span>
                </div>
                <div>
                  <strong>
                    {t("eventSounds.dataSelection.detail.note.title")}
                    {": "}
                  </strong>

                  <Tooltip title={<span>{note}</span>} placement="top">
                    <span>
                      {t("eventSounds.dataSelection.detail.note.text")}
                    </span>
                  </Tooltip>
                </div>
              </div>
              <MenuItem style={{ paddingLeft: "unset" }}>
                <Button
                  size="small"
                  fullWidth
                  style={{ paddingLeft: "unset" }}
                  className={classes.actionButton}
                  onClick={handleRedirectGrafana}
                >
                  {t("eventSounds.similaritySearch.checkSound")}
                </Button>
              </MenuItem>
            </Box>
          </>
        )}
      </Popover>
    </div>
  );
};
