import React, { useEffect, useMemo, Suspense, useRef } from "react";
import {
  Route,
  Routes,
  Navigate,
  useParams,
  BrowserRouter,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "store";
import "./non-theme-styles.css";
import urls from "shared/appUrls";
import EPermissions from "shared/permissions";
import { dataLabelingRedirect, fromGrafanaRedirect } from "shared/helpers";
import { createInstance, OptimizelyProvider } from "@optimizely/react-sdk";
import { getOptimizelyKey } from "./settings";
import checkGrafanaRedirect from "shared/grafanaRedirect";
import Layout from "components/layout";
import Spinner from "components/spinner";
import lazyImportHandler from "./shared/lazyImportHandler";
import LoggedInError from "screens/loggedInError";
import usePermissions from "shared/hooks/usePermissions";
import AccessDenied from "screens/accessDenied";
import GrafanaDashboard from "components/machine/detail/grafanaDashboard";
import GeneralTab from "components/machine/detail/general";
import Sounds from "components/machine/detail/sounds";
import { ERoleCodename } from "types/role";
import { ChannelsList } from "components/devices/detail/list";
import DataSelectionTab from "components/eventSounds/dataSelection";
import SavedDatasetTab from "components/eventSounds/savedDataset";
import GroupedDatasetTab from "components/eventSounds/groupedDataset";
import SimilaritySearchTab from "components/eventSounds/similaritySearch";
import { FacilityTab } from "components/projects/detail/facilityList";
import UsersTab from "components/projects/detail/usersList";
import { ReportsTab } from "components/projects/detail/reportsList";
import { TemporaryTokenTab } from "components/projects/detail/temporaryTokenList";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

const EventListTab = lazyImportHandler(
  () => import("components/machine/detail/events/list")
);
const PlacementsListTab = lazyImportHandler(
  () => import("components/machine/detail/placements/list")
);
const ModelTab = lazyImportHandler(
  () => import("components/machine/detail/model/model")
);
const AnalysisTab = lazyImportHandler(
  () => import("components/machine/detail/analysis")
);
const CustomModelOverview = lazyImportHandler(
  () => import("components/machine/detail/customModelOverview")
);
const Dashboard = lazyImportHandler(() => import("screens/dashboard"));
const Login = lazyImportHandler(() => import("screens/login"));
const Users = lazyImportHandler(() => import("screens/users"));
const ForgotPassword = lazyImportHandler(
  () => import("screens/forgotPassword")
);
const UsersDetail = lazyImportHandler(() => import("screens/users/detail"));
const MachineDetail = lazyImportHandler(
  () => import("screens/machines/detail")
);
const DeviceDetail = lazyImportHandler(() => import("screens/devices/detail"));
const DeviceModelTab = lazyImportHandler(
  () => import("components/devices/detail/model/model")
);
const CompanyDetail = lazyImportHandler(
  () => import("screens/companies/detail")
);
const ProjectDetail = lazyImportHandler(
  () => import("screens/projects/detail")
);
const FacilityDetail = lazyImportHandler(
  () => import("screens/facilities/detail")
);
const NewPassword = lazyImportHandler(() => import("screens/newPassword"));
const PageNotFound = lazyImportHandler(() => import("screens/pageNotFound"));
const Devices = lazyImportHandler(() => import("screens/devices"));
const Companies = lazyImportHandler(() => import("screens/companies"));
const Helpdesk = lazyImportHandler(() => import("screens/helpdesk"));
const Models = lazyImportHandler(() => import("screens/models"));
const AccessTokens = lazyImportHandler(() => import("screens/accessTokens"));
const EventSounds = lazyImportHandler(() => import("screens/eventSounds"));
const ServiceManager = lazyImportHandler(
  () => import("screens/serviceManager")
);
const ServiceManagerDetail = lazyImportHandler(
  () => import("screens/serviceManager/detail")
);

const App: React.FC = () => {
  const user = useSelector((state: AppState) => state.auth.user);

  const dashboardAuthorisation = usePermissions([
    EPermissions.LIST_ALL_MACHINES,
    EPermissions.LIST_OWN_MACHINES,
  ]);
  const readAuthorisation = usePermissions([
    EPermissions.READ_ANY_USER,
    EPermissions.READ_OWN_USER,
  ]);
  const readtMachineAuthorisation = usePermissions([
    EPermissions.READ_ANY_MACHINE,
    EPermissions.READ_OWN_MACHINE,
  ]);
  const updateAuthorisation = usePermissions([
    EPermissions.UPDATE_OWN_USER,
    EPermissions.UPDATE_ANY_USER,
  ]);
  const listDeviceAuthorisation = usePermissions([
    EPermissions.LIST_OWN_DEVICES,
    EPermissions.LIST_ALL_DEVICES,
  ]);
  const listCompaniesAuthorisation = usePermissions([
    EPermissions.LIST_ALL_COMPANIES,
    EPermissions.LIST_OWN_COMPANIES,
  ]);
  const projectsAuthorisation = usePermissions([
    EPermissions.LIST_ALL_PROJECTS,
    EPermissions.LIST_OWN_PROJECTS,
  ]);
  const facilitiesAuthorisation = usePermissions([
    EPermissions.LIST_ALL_FACILITIES,
    EPermissions.LIST_OWN_FACILITIES,
  ]);
  const temporaryTokenAuthorisation = usePermissions([
    EPermissions.LIST_ALL_TEMPORARY_TOKENS,
  ]);
  const createFacilitiesAuthorisation = usePermissions([
    EPermissions.CREATE_ANY_FACILITY,
    EPermissions.CREATE_ANY_FACILITY,
    EPermissions.CREATE_OWN_FACILITY,
  ]);
  const monthlyReportAuthorisation = usePermissions([
    EPermissions.CREATE_OWN_MONTHLY_REPORT,
    EPermissions.CREATE_ANY_MONTHLY_REPORT,
  ]);
  const projectsTemporaryTokenAuthorisation = usePermissions([
    EPermissions.CREATE_ANY_TEMPORARY_TOKEN,
    EPermissions.CREATE_OWN_TEMPORARY_TOKEN,
  ]);

  useEffect(() => {
    checkGrafanaRedirect();
  }, [user]);

  const optimizely = useMemo(
    () =>
      createInstance({
        sdkKey: getOptimizelyKey(),
      }),
    []
  );

  const isAdmin = user?.role === "nsw_admin";
  const role = user?.role;

  return (
    <OptimizelyProvider
      optimizely={optimizely as any}
      user={{
        id: user?.id?.toString() || "",
        attributes: {
          username: user?.username,
        },
      }}
    >
      <Suspense fallback={<Spinner />}>
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <Routes>
              <Route>
                {!user ? (
                  <Route path={urls.appRoot.index()}>
                    <Route index element={<Login />} />
                    <Route
                      path={urls.forgotPassword.index()}
                      element={<ForgotPassword />}
                    />
                    <Route
                      path={urls.newPassword.pattern()}
                      element={<NewPassword />}
                    />
                    <Route path="*" element={<Login />} />
                  </Route>
                ) : (
                  <Route path={urls.appRoot.index()} element={<Layout />}>
                    <Route
                      path={urls.newPassword.pattern()}
                      element={<NewPassword />}
                    />
                    <Route
                      path={urls.newPassword.pattern()}
                      element={
                        dashboardAuthorisation ? (
                          <Dashboard />
                        ) : (
                          <AccessDenied />
                        )
                      }
                    />
                    <Route
                      index
                      element={
                        dashboardAuthorisation ? (
                          <Dashboard />
                        ) : (
                          <AccessDenied />
                        )
                      }
                    />
                    <Route
                      path={urls.users.detailPattern()}
                      element={
                        readAuthorisation ? <UsersDetail /> : <AccessDenied />
                      }
                    />
                    <Route
                      path={urls.users.detailPattern()}
                      element={<UsersDetail />}
                    />
                    <Route
                      path={urls.dataLabeling.pattern()}
                      loader={({ match }: any) => (
                        <Navigate to={dataLabelingRedirect(match)} />
                      )}
                    />
                    <Route
                      path={urls.dataLabeling.fromGrafana()}
                      element={
                        <Navigate to={fromGrafanaRedirect(window.location)} />
                      }
                      loader={fromGrafanaRedirect}
                    />
                    <Route
                      path={urls.machines.detailPattern()}
                      element={
                        readtMachineAuthorisation ? (
                          <MachineDetail />
                        ) : (
                          <AccessDenied />
                        )
                      }
                    >
                      <Route
                        path={urls.machines.detailConfigPattern()}
                        element={<GeneralTab />}
                      />
                      <Route
                        path={urls.machines.detailPattern()}
                        element={<GrafanaDashboard />}
                      />
                      <Route
                        path={urls.machines.detailPlacementsPattern()}
                        element={<PlacementsListTab />}
                      />
                      <Route
                        path={urls.machines.detailSoundsPattern()}
                        element={<Sounds />}
                      />
                      <Route
                        path={urls.machines.detailEventsPattern()}
                        element={<EventListTab />}
                      />
                      {role === ERoleCodename.nswAdmin && (
                        <Route
                          path={urls.machines.detailModelPattern()}
                          element={<ModelTab />}
                        />
                      )}
                      <Route
                        path={urls.machines.detailAnalysisPattern()}
                        element={<AnalysisTab />}
                      />
                      {role === ERoleCodename.nswAdmin && (
                        <Route
                          path={urls.machines.detailCustomModelPattern()}
                          element={<CustomModelOverview />}
                        />
                      )}
                    </Route>
                    {user && !user.isInternal ? (
                      <Route
                        path={urls.users.index()}
                        element={
                          updateAuthorisation ? <Users /> : <AccessDenied />
                        }
                      />
                    ) : null}
                    {user && !user.isInternal ? (
                      <Route
                        path={urls.devices.index()}
                        element={
                          listDeviceAuthorisation ? (
                            <Devices />
                          ) : (
                            <AccessDenied />
                          )
                        }
                      />
                    ) : null}
                    {user && !user.isInternal ? (
                      <Route
                        path={urls.devices.detailPattern()}
                        element={
                          listDeviceAuthorisation ? (
                            <DeviceDetail />
                          ) : (
                            <AccessDenied />
                          )
                        }
                      >
                        <Route
                          path={urls.devices.detailPattern()}
                          element={<ChannelsList />}
                        />
                        {role === ERoleCodename.nswAdmin && (
                          <Route
                            path={urls.devices.detailModelPattern()}
                            element={<DeviceModelTab />}
                          />
                        )}
                      </Route>
                    ) : null}
                    {user && !user.isInternal ? (
                      <Route
                        path={urls.companies.index()}
                        element={
                          listCompaniesAuthorisation ? (
                            <Companies />
                          ) : (
                            <AccessDenied />
                          )
                        }
                      />
                    ) : null}
                    {user && !user.isInternal ? (
                      <Route
                        path={urls.companies.detailPattern()}
                        element={
                          listCompaniesAuthorisation ? (
                            <CompanyDetail />
                          ) : (
                            <AccessDenied />
                          )
                        }
                      />
                    ) : null}
                    {user && !user.isInternal ? (
                      <Route
                        path={urls.projects.detailPattern()}
                        element={
                          projectsAuthorisation ? (
                            <ProjectDetail />
                          ) : (
                            <AccessDenied />
                          )
                        }
                      >
                        <Route
                          path={urls.projects.detailGeneralPattern()}
                          element={<FacilityTab />}
                        />
                        <Route
                          path={urls.projects.detailUsersPattern()}
                          element={
                            createFacilitiesAuthorisation ? (
                              <UsersTab />
                            ) : (
                              <AccessDenied />
                            )
                          }
                        ></Route>
                        {isAdmin ? (
                          <Route
                            path={urls.projects.detailReportsPattern()}
                            element={
                              monthlyReportAuthorisation ? (
                                <ReportsTab />
                              ) : (
                                <AccessDenied />
                              )
                            }
                          />
                        ) : null}
                        <Route
                          path={urls.projects.detailTemporaryTokenPattern()}
                          element={
                            projectsTemporaryTokenAuthorisation ? (
                              <TemporaryTokenTab />
                            ) : (
                              <AccessDenied />
                            )
                          }
                        />
                      </Route>
                    ) : null}
                    {user && !user.isInternal ? (
                      <Route
                        path={urls.facilities.detailPattern()}
                        element={
                          facilitiesAuthorisation ? (
                            <FacilityDetail />
                          ) : (
                            <AccessDenied />
                          )
                        }
                      />
                    ) : null}
                    {isAdmin ? (
                      <Route
                        path={urls.eventSounds.index()}
                        element={<EventSounds />}
                      >
                        <Route
                          path={urls.eventSounds.detailEventSelection()}
                          element={<DataSelectionTab />}
                        />
                        <Route
                          path={urls.eventSounds.detailCollectionClasses()}
                          element={<SavedDatasetTab />}
                        />
                        <Route
                          path={urls.eventSounds.detailDatasets()}
                          element={<GroupedDatasetTab />}
                        />
                        <Route
                          path={urls.eventSounds.detailSimilaritySearch()}
                          element={<SimilaritySearchTab />}
                        />
                      </Route>
                    ) : null}
                    {isAdmin ? (
                      <Route
                        path={urls.serviceManager.index()}
                        element={<ServiceManager />}
                      />
                    ) : null}
                    {isAdmin ? (
                      <Route
                        path={urls.serviceManager.detailPattern()}
                        element={<ServiceManagerDetail />}
                      />
                    ) : null}
                    <Route path={urls.models.index()} element={<Models />} />
                    <Route
                      path={urls.accessTokens.index()}
                      element={
                        temporaryTokenAuthorisation ? (
                          <AccessTokens />
                        ) : (
                          <AccessDenied />
                        )
                      }
                    />
                    <Route
                      path={urls.helpdesk.index()}
                      element={<Helpdesk />}
                    />
                    <Route
                      path={urls.appRoot.temporaryToken()}
                      element={<LoggedInError />}
                    />
                    <Route
                      path={urls.appRoot.all()}
                      element={<PageNotFound />}
                    />
                  </Route>
                )}
              </Route>
            </Routes>
          </QueryParamProvider>
        </BrowserRouter>
      </Suspense>
    </OptimizelyProvider>
  );
};

export default App;
