import { memo, useMemo, useEffect, useCallback } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import actions, { createUserAction } from "store/createUser/actions";

import UserInformationForm from "components/users/userInformationForm";
import CompaniesProjectsForm from "components/users/companiesProjectsForm/create";
import Modal from "components/stepModal";
import { ContentProps } from "components/stepModal";
import FormModalHeader from "components/form/parts/formModalHeader";
import { ICreateUserFormFields } from "types/user";
import { ICompanyList } from "types/company";
import { IProjectListExtended } from "types/project";
import { AppState } from "store";
import isEqual from "react-fast-compare";
import { useNavigate } from "react-router-dom";

const { reset, prepare } = actions;

type UserModalDataType = {
  user?: ICreateUserFormFields;
  companies?: ICompanyList[];
  projects?: IProjectListExtended[];
};

const CreateUser = memo(({ onClose, forcedOpen = false }: any) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const showDesktop = useMediaQuery(theme.breakpoints.up(768), { noSsr: true });
  const { userData, modalOpened } = useSelector(
    (state: AppState) => state.createUser,
    isEqual
  );
  const dispatch: any = useDispatch();
  const history = useNavigate();

  useEffect(() => {
    if (userData) {
      dispatch(reset());
      dispatch(createUserAction(userData, history));
    }
  }, [dispatch, userData, history]);

  const handleCreateUserModalFinish = useCallback(
    (data?: UserModalDataType) => {
      if (data && data.user) {
        dispatch(
          prepare({
            userData: {
              first_name: data.user.firstName,
              last_name: data.user.lastName,
              username: data.user.email,
              email: data.user.email,
              phone: data.user.phone,
              role: data.user.role,
              language: data.user.language,
              display_currency: data.user.displayCurrency,
            },
          })
        );
      }
    },
    []
  );

  const steps = useMemo(
    () => [
      {
        label: `${t("form.userInformation.title")}`,
        formId: "user-information-form",
        Component: ({
          onSuccess,
          id,
          data,
        }: ContentProps<UserModalDataType>) => {
          return (
            <>
              {showDesktop && (
                <FormModalHeader title={t("form.userInformation.title")} />
              )}
              <UserInformationForm onSuccess={onSuccess} id={id} data={data} />
            </>
          );
        },
      },
      {
        label: `${t("form.companiesProjects.title")}`,
        Component: () => (
          <>
            {showDesktop && (
              <FormModalHeader title={t("form.companiesProjects.title")} />
            )}
            <CompaniesProjectsForm
              needsConfirmation={false}
              onChangeCP={() => false}
            />
          </>
        ),
      },
    ],
    [showDesktop, t]
  );

  return (
    <Modal<UserModalDataType>
      open={modalOpened || forcedOpen}
      steps={steps}
      loading={false}
      closeModal={onClose}
      finishButtonDataTestId="testCreateUserButton"
      finishButtonLabel={t("createUser")}
      modalTitle={t("createUser")}
      handleFinish={handleCreateUserModalFinish}
    />
  );
});

export default CreateUser;
