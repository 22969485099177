import endpoints from "api/endpoints";
import moment from "moment";
import getAudioContext from "components/dataLabeling/audio/getAudioContext";
import axiosNeuron from "api/axios";
import axios, { Canceler } from "axios";
let cancel: Canceler | undefined;
export async function getAudio(
  placement: number,
  start: number,
  length: number,
  timezoneOffset: number | string,
  sampleRate: number,
  signal: AbortSignal
) {
  const ctx = getAudioContext(sampleRate);
  const start_time = moment.unix(start).format("YYYY-MM-DD HH:mm:ss.SSS");
  const end_time = moment
    .unix(start + length)
    .format("YYYY-MM-DD HH:mm:ss.SSS");

  const url = endpoints.audio.default;
  let data: any;

  try {
    const { data: dataFromApi } = await axiosNeuron.get(
      `${url}?placement=${encodeURIComponent(
        placement
      )}&start_time=${encodeURIComponent(start_time).replace(
        /\./g,
        "%2E"
      )}&end_time=${encodeURIComponent(end_time).replace(
        /\./g,
        "%2E"
      )}&tz=${encodeURIComponent(timezoneOffset)}`,
      {
        responseType: "arraybuffer",
        cancelToken: signal
          ? undefined
          : new axios.CancelToken(function executor(c) {
              cancel = c;
            }),
      }
    );
    data = dataFromApi;
    const promise = new Promise((resolve, reject) => {
      if (!data.byteLength) {
        return reject(null);
      }
      ctx.decodeAudioData(
        data,
        (buffer: any) => resolve(buffer),
        (e: any) => reject(e)
      );
    });
    return await promise;
  } catch (err) {
    if (cancel) {
      cancel("Operation canceled");
      return null;
    }
    if (
      data?.message &&
      data?.message.match(
        /System error: index .* is out of bounds for axis .* with size .*/
      )
    ) {
      return null;
    }
  }
}
